<template>
  <SingleComponent
    :loading="loading"
    :mode="mode"
    @add-button="addButton"
    @edit-button="editButton"
  >
    <v-text-field
      v-model="input.accuracy"
      label="Точность"
      :rules="[$validate.required]"
    >
    </v-text-field>
    <v-text-field
      v-model="input.coef"
      label="Коэффициент"
      :rules="[$validate.required]"
    >
    </v-text-field>
    <v-textarea
      v-model="input.description"
      label="Описание"
      :rules="[$validate.required]"
    >
    </v-textarea>
    <v-text-field
      v-model="input.name"
      label="Название"
      :rules="[$validate.required]"
    >
    </v-text-field>
    <v-select
      v-model="input.parentId"
      label="Родительская категория"
      :rules="[$validate.required]"
      :items="
        MEASURE.allMeasure.filter((el) => {
          if (el.name !== input.name) {
            return el;
          }
        })
      "
      item-text="name"
      item-value="id"
    >
    </v-select>
  </SingleComponent>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import SingleComponent from "@/components/SingleComponent";
import measure from "@/model/measure";
export default {
  components: { SingleComponent },
  data() {
    return {
      loading: true,
      mode: "Добавить",
      input: {},
      roles: [{ id: 1, name: "Админ" }],
      departments: [
        { id: 1, name: "Аян-Город" },
        { id: 0, name: "Аян-Караганда" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      MEASURE: "Measure/STATE",
    }),
  },
  async created() {
    let body = {};
    if (this.$route.params.id) {
      this.mode = "Сохранить";
      body = await this.getMeasure(this.$route.params.id);
    }
    let model = new measure();
    this.input = model.setInput(this.mode, body);
    await this.setEmptys();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      addMeasure: "Measure/ADD_MEASURE",
      getMeasure: "Measure/GET_MEASURE",
      editMeasure: "Measure/EDIT_MEASURE",
      allMeasure: "Measure/GET_ALL_MEASURE",
    }),
    async setEmptys() {
      if (this.MEASURE.allMeasure.length === 0) {
        await this.allMeasure();
      }
    },
    async addButton() {
      this.loading = true;
      let response = await this.addMeasure(this.input);
      if (response.type === "success") {
        this.$router.push("/measure");
        this.$notify({
          group: "app",
          type: "success",
          title: "Успех",
        });
      } else {
        this.$notify({
          group: "app",
          type: "error",
          title: "Ошибка",
          text: this.getError(response.errors),
        });
      }
      this.loading = false;
    },
    async editButton() {
      this.loading = true;
      let response = await this.editMeasure(this.input);
      if (response.type === "success") {
        this.$router.push("/measure");
        this.$notify({
          group: "app",
          type: "success",
          title: "Успех",
        });
      } else {
        this.$notify({
          group: "app",
          type: "error",
          title: "Ошибка",
          text: response.message,
        });
      }
      this.loading = false;
    },
    getError(errors) {
      let error = "";
      for (let i = 0; i < errors.length; i++) {
        error += errors[i].defaultMessage + ", ";
      }
      return error;
    },
  },
};
</script>
