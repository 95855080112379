export default class test {
  constructor() {
    this.input = {
      accuracy: null,
      coef: null,
      description: "",
      id: null,
      name: "",
      parentId: null,
    };
    this.body = [];
  }
  setInput(mode, data) {
    if (mode === "Сохранить") {
      this.input.accuracy = data.accuracy;
      this.input.coef = data.coef;
      this.input.description = data.description;
      this.input.id = data.id;
      this.input.name = data.name;
      this.input.parentId = data.parentMeasure.id;
    }
    return this.input;
  }
  setBody(data) {
    if (data) {
      for (let i = 0; i < data.length; i++) {
        this.body.push({
          fields: [
            {
              id: 1,
              name: data[i].id,
              type: "string",
            },
            {
              id: 2,
              name: data[i].createdAt,
              type: "string",
            },
            {
              id: 3,
              name: data[i].updatedAt,
              type: "string",
            },
            {
              id: 4,
              name: data[i].name,
              type: "string",
            },
            {
              id: 5,
              name: data[i].description,
              type: "string",
            },
            {
              id: 6,
              name: data[i].coef,
              type: "string",
            },
            {
              id: 7,
              name: data[i].parentMeasure.name,
              type: "string",
            },
            {
              id: 8,
              name: data[i].deleted,
              type: "string",
            },
            {
              id: 9,
              name: data[i].accuracy,
              type: "string",
            },
            {
              id: 10,
              name: data[i].spacing,
              type: "string",
            },
          ],
        });
      }
    }
    return this.body;
  }
}
